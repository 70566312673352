import _ from "lodash";
import { useMemo, useState } from "react";
import { Form, Grid, Label } from "semantic-ui-react";
import { baseModel, deploymentType } from "../../../api_generated";
import Checkbox from "../../../components/Checkbox";
import Dropdown from "../../../components/Dropdown";
import Input from "../../../components/Input";
import Markdown from "../../../components/Markdown";
import FieldWrapper from "../../../components/forms/json-schema-form/fields/FieldWrapper";
import { titleInlineStyling } from "../../../components/forms/json-schema-form/styling";
import { useDeploymentsQuery } from "../../../query";
import { SEMANTIC_BLUE, SEMANTIC_WHITE } from "../../../utils/colors";
import ParametersSchema from "./schema.json";
import { useConfigState, useDispatch } from "./store";
import {
    getAdapterRankDropdownOptions,
    getLLMDropdownOptions,
    getSelectedLLM,
    getTargetModulesDefaultValue,
    getTargetModulesOptions,
    getTaskDropdownOptions,
} from "./utils";

const getInputValue = (
    path: string,
    localState: any,
    config: typeof ParametersSchema,
    defaultValue: string | boolean | number | undefined | null = "",
) => {
    if (_.has(localState, path)) {
        return _.get(localState, path);
    }

    if (_.has(config, path)) {
        return _.get(config, path);
    }

    return defaultValue;
};

const ParametersForm = (props: {
    baseModels?: baseModel[];
    modelDescription: string;
    nextVersion?: number;
    readOnly?: boolean;
    setModelDescription: React.Dispatch<React.SetStateAction<string>>;
}) => {
    // Parent state:
    const { baseModels, modelDescription, nextVersion, readOnly, setModelDescription } = props;
    const { config, schema } = useConfigState();
    const dispatch = useDispatch();

    // Local state:
    const [localState, setLocalState] = useState({});

    // Query state:
    // Fetch the deployments so we can order the base model dropdown:
    const { data: deployments } = useDeploymentsQuery({
        refetchInterval: false,
        refetchOnWindowFocus: false,
        enabled: !Boolean(readOnly),
    });
    const serverlessDeployments = deployments?.filter((dep) => dep.type === deploymentType.SERVERLESS);

    // In order to communicate to React Tracked that this component always
    // needs to be re-rendered when the config object changes, we create this
    // unused variable that stringifies the entire object.
    // The bug we were running into was that when submitting the config to the
    // server, we were submitting a stale version of the config that did not get
    // updated until the component was re-rendered.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const configJSON = JSON.stringify(config); // DO NOT DELETE!

    // Event listeners
    const updateLocalState = (
        path: string,
        value: string | boolean | (string | number | boolean | undefined | null)[],
    ) => {
        if (path === "base_model") {
            const newSelectedBaseModel = getSelectedLLM(value as string, baseModels);
            _.set(localState, "target_modules", getTargetModulesDefaultValue(newSelectedBaseModel));
        }
        setLocalState(_.set(localState, path, value));
    };

    const updateConfigValue = (path: string, convertedValue: string | boolean) => {
        if (path === "base_model") {
            const newSelectedBaseModel = getSelectedLLM(convertedValue as string, baseModels);
            dispatch({
                type: "UPDATE_CONFIG_PROPERTY",
                field: "target_modules",
                value: getTargetModulesDefaultValue(newSelectedBaseModel),
            });
        }
        dispatch({ type: "UPDATE_CONFIG_PROPERTY", field: path, value: convertedValue });
    };

    let selectedBaseModel = useMemo(
        () => getSelectedLLM(config?.base_model, baseModels),
        [config?.base_model, baseModels],
    );
    let targetModulesOptions = useMemo(
        () => getTargetModulesOptions(selectedBaseModel, config?.target_modules),
        [selectedBaseModel],
    );
    let targetModulesDefaultValue = useMemo(() => getTargetModulesDefaultValue(selectedBaseModel), [selectedBaseModel]);

    return (
        <Grid>
            <Grid.Row columns={2} style={{ paddingBottom: 0, marginTop: `${16 / 14}rem` }}>
                <Grid.Column>
                    <Form>
                        <FieldWrapper
                            title="Large Language Model to Fine-tune"
                            description="Pick from a set of popular LLMs of different sizes across a variety of architecture types."
                            path="base_model"
                            schema={{}}
                            style={{ display: "inherit" }}
                        >
                            <Dropdown
                                path="base_model"
                                title="Large Language Model to Fine-tune"
                                error={false}
                                multiple={false}
                                fluid={true}
                                options={getLLMDropdownOptions(config?.base_model, baseModels, config)}
                                value={getInputValue("base_model", localState, config)}
                                defaultValue=""
                                readOnly={readOnly}
                                setConfig={updateConfigValue}
                                setLocalState={updateLocalState}
                                schemaPath="base_model"
                            />
                        </FieldWrapper>
                        <FieldWrapper path="adapter" schema={{}} style={{ display: "inherit" }}>
                            <div style={{ display: "inline-block" }}>
                                <Checkbox
                                    path="adapter"
                                    error={false}
                                    value={getInputValue("adapter", localState, config) === "turbo_lora"}
                                    readOnly={readOnly}
                                    onChange={(clicked) => {
                                        const newValue = clicked ? "turbo_lora" : "lora";
                                        updateLocalState("adapter", newValue);
                                        updateConfigValue("adapter", newValue);
                                    }}
                                    style={{ width: "100%" }}
                                />
                                <Label size="small" style={{ color: SEMANTIC_WHITE, backgroundColor: SEMANTIC_BLUE }}>
                                    {" "}
                                    New{" "}
                                </Label>{" "}
                                &nbsp;
                                <span style={{ ...titleInlineStyling }}> Train with Turbo LoRA ⚡</span>
                            </div>
                            <div style={{ marginLeft: "2em", marginTop: "0.5em" }}>
                                <Markdown
                                    secondary={true}
                                    children="
                                        Improve your inference throughput with our proprietary training method. Training jobs will take longer to train and are priced at 2x the standard pricing.

                                        [Learn more about Turbo LoRA.](https://docs.predibase.com/sdk-guide/SDKv2/ConfigClasses/FineTuningConfig#adapter)
                                    "
                                />
                            </div>
                        </FieldWrapper>
                        <FieldWrapper
                            title="Fine-Tuning Task"
                            description="Choose the type of task to fine-tune the base model to complete. [Learn more about the available task types in the docs.](https://docs.predibase.com/sdk-guide/SDKv2/ConfigClasses/FineTuningConfig#task)"
                            path="task"
                            schema={{}}
                            style={{ display: "inherit" }}
                        >
                            <Dropdown
                                path="task"
                                title="Fine-Tuning Task"
                                error={false}
                                multiple={false}
                                fluid={true}
                                options={getTaskDropdownOptions(config)}
                                value={getInputValue("task", localState, config)}
                                defaultValue={schema?.properties.task.default}
                                readOnly={readOnly}
                                setConfig={updateConfigValue}
                                setLocalState={updateLocalState}
                                schemaPath="rank"
                            />
                        </FieldWrapper>
                        <FieldWrapper
                            title="Adapter Rank"
                            description="Increasing the adapter rank increases the capacity of your fine-tuned model. Higher model capacity may improve training performance, but may also increase GPU memory requirements and training duration."
                            path="rank"
                            schema={{}}
                            style={{ display: "inherit" }}
                        >
                            <Dropdown
                                path="rank"
                                title="Adapter Rank"
                                error={false}
                                multiple={false}
                                fluid={true}
                                options={getAdapterRankDropdownOptions(config)}
                                value={getInputValue("rank", localState, config)}
                                defaultValue={schema?.properties.rank.default}
                                readOnly={readOnly}
                                setConfig={updateConfigValue}
                                setLocalState={updateLocalState}
                                schemaPath="rank"
                            />
                        </FieldWrapper>
                        <FieldWrapper
                            title="Target Modules"
                            description="Adding target modules increases the capacity of your fine-tuned model. More target modules may improve training performance, but may also increase GPU memory requirements and training duration."
                            path="rank"
                            schema={{}}
                            style={{ display: "inherit" }}
                        >
                            <Dropdown
                                path="target_modules"
                                title="Target Modules"
                                error={false}
                                multiple={true}
                                fluid={true}
                                options={targetModulesOptions}
                                value={getInputValue("target_modules", localState, config)}
                                defaultValue={targetModulesDefaultValue}
                                readOnly={readOnly}
                                setConfig={updateConfigValue}
                                setLocalState={updateLocalState}
                                schemaPath="target_modules"
                            />
                        </FieldWrapper>
                        <FieldWrapper
                            title="Epochs"
                            description="Number of times the adapter sees the entire dataset."
                            path="epochs"
                            schema={{}}
                            style={{ display: "inherit" }}
                        >
                            <Input
                                path="epochs"
                                title="Epochs"
                                error={false}
                                value={getInputValue("epochs", localState, config)}
                                defaultValue={schema?.properties.epochs.default}
                                readOnly={readOnly}
                                setConfig={updateConfigValue}
                                setLocalState={updateLocalState}
                                schemaPath="epochs"
                                style={{ width: "100%" }}
                            />
                        </FieldWrapper>
                        <FieldWrapper
                            title="Learning Rate"
                            description="Size of the adapter weights update at each batch."
                            path="learning_rate"
                            schema={{}}
                            style={{ display: "inherit" }}
                        >
                            <Input
                                path="learning_rate"
                                title="Learning Rate"
                                error={false}
                                value={getInputValue("learning_rate", localState, config)}
                                defaultValue={schema?.properties.learning_rate.default}
                                readOnly={readOnly}
                                setConfig={updateConfigValue}
                                setLocalState={updateLocalState}
                                schemaPath="learning_rate"
                                style={{ width: "100%" }}
                            />
                        </FieldWrapper>
                        <FieldWrapper
                            title="Enable Early Stopping"
                            description="Enable early stopping of training if validation loss plateaus."
                            path="enable_early_stopping"
                            schema={{}}
                            style={{ display: "inherit" }}
                        >
                            <Checkbox
                                path="enable_early_stopping"
                                label="Enable Early Stopping"
                                error={false}
                                value={getInputValue("enable_early_stopping", localState, config, true)}
                                readOnly={readOnly}
                                onChange={(checked) => {
                                    const newValue = Boolean(checked);
                                    updateLocalState("enable_early_stopping", newValue);
                                    updateConfigValue("enable_early_stopping", newValue);
                                }}
                                style={{ width: "100%" }}
                            />
                        </FieldWrapper>
                        <FieldWrapper
                            title={nextVersion ? `Description for Version ${nextVersion}` : "Description"}
                            description="What you intend to try in this experiment (ex. “first model” or “learning rate auto”)"
                            path="description"
                            schema={{}}
                            style={{ display: "inherit" }}
                        >
                            <Input
                                path="description"
                                title="Description"
                                error={false}
                                value={modelDescription}
                                defaultValue=""
                                readOnly={readOnly}
                                setConfig={() => {}}
                                setLocalState={(path, value) => setModelDescription(value)}
                                schemaPath="description"
                                style={{ width: "100%" }}
                            />
                        </FieldWrapper>
                    </Form>
                </Grid.Column>
                <Grid.Column></Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default ParametersForm;
