import { capitalize } from "lodash";
import { role } from "../../api_generated";

export const generateUserRoleDropdownOptions = () => {
    const roleKeys = Object.keys(role).filter((key) => isNaN(Number(key)));
    return roleKeys.map((key) => ({
        text: capitalize(key.toLowerCase()),
        value: key.toLowerCase(),
    }));
};
