// @generated by protobuf-ts 2.9.1 with parameter long_type_number
// @generated from protobuf file "predibase_api/artifacts/v1/artifacts.proto" (package "proto.artifacts.v1", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message proto.artifacts.v1.LudwigDataset
 */
export interface LudwigDataset {
    /**
     * The name of the dataset.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * The prediction tasks associated with the dataset.
     *
     * @generated from protobuf field: repeated string tasks = 2;
     */
    tasks: string[];
    /**
     * The data modalities associated with the dataset.
     *
     * @generated from protobuf field: repeated string data_modalities = 3;
     */
    dataModalities: string[];
}
/**
 * @generated from protobuf message proto.artifacts.v1.AvailableDatasetsResponse
 */
export interface AvailableDatasetsResponse {
    /**
     * The list of available datasets.
     *
     * @generated from protobuf field: repeated proto.artifacts.v1.LudwigDataset datasets = 1;
     */
    datasets: LudwigDataset[];
}
/**
 * @generated from protobuf message proto.artifacts.v1.PresignedUrlForUploadRequest
 */
export interface PresignedUrlForUploadRequest {
    /**
     * Required. The MIME type of the file to be uploaded.
     *
     * @generated from protobuf field: string mime_type = 1;
     */
    mimeType: string;
    /**
     * Optional. The duration for which the presigned URL should remain
     * valid, in seconds. Defaults to 3600 seconds if not set.
     *
     * @generated from protobuf field: int32 expiry_seconds = 2;
     */
    expirySeconds: number;
    /**
     * @generated from protobuf field: proto.artifacts.v1.ArtifactType artifact_type = 3;
     */
    artifactType: ArtifactType;
    /**
     * Optional. The specific key for the object within the bucket. A UUID will be
     * autogenerated for this value if not set.
     *
     * @generated from protobuf field: string object_name = 4;
     */
    objectName: string;
}
/**
 * @generated from protobuf message proto.artifacts.v1.PresignedUrlForUploadResponse
 */
export interface PresignedUrlForUploadResponse {
    /**
     * Required. The presigned URL.
     *
     * @generated from protobuf field: string url = 1;
     */
    url: string;
    /**
     * Required. The name of the blob that will be created upon upload.
     *
     * @generated from protobuf field: string object_name = 2;
     */
    objectName: string;
    /**
     * Optional. Any headers that must be sent with the PUT request using
     * the presigned URL.
     *
     * @generated from protobuf field: map<string, string> required_headers = 3;
     */
    requiredHeaders: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message proto.artifacts.v1.RegisterUploadedFileAsDatasetRequest
 */
export interface RegisterUploadedFileAsDatasetRequest {
    /**
     * The name of the dataset to register.
     *
     * @generated from protobuf field: string dataset_name = 1;
     */
    datasetName: string;
    /**
     * The name of the uploaded file blob containing the dataset.
     *
     * @generated from protobuf field: string object_name = 2;
     */
    objectName: string;
    /**
     * The file name of the uploaded file including the file extension.
     *
     * @generated from protobuf field: string file_name = 3;
     */
    fileName: string;
}
/**
 * @generated from protobuf enum proto.artifacts.v1.ArtifactType
 */
export enum ArtifactType {
    /**
     * @generated from protobuf enum value: ARTIFACT_TYPE_UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: ARTIFACT_TYPE_THUMBNAIL = 1;
     */
    THUMBNAIL = 1,
    /**
     * @generated from protobuf enum value: ARTIFACT_TYPE_QUERY_RESULT = 2;
     */
    QUERY_RESULT = 2,
    /**
     * @generated from protobuf enum value: ARTIFACT_TYPE_DATASET = 3;
     */
    DATASET = 3,
    /**
     * @generated from protobuf enum value: ARTIFACT_TYPE_DEPLOYMENT = 4;
     */
    DEPLOYMENT = 4,
    /**
     * @generated from protobuf enum value: ARTIFACT_TYPE_MODEL = 5;
     */
    MODEL = 5,
    /**
     * @generated from protobuf enum value: ARTIFACT_TYPE_VIZ = 6;
     */
    VIZ = 6,
    /**
     * @generated from protobuf enum value: ARTIFACT_TYPE_CACHE = 7;
     */
    CACHE = 7,
    /**
     * @generated from protobuf enum value: ARTIFACT_TYPE_WORKSPACE = 8;
     */
    WORKSPACE = 8,
    /**
     * @generated from protobuf enum value: ARTIFACT_TYPE_EXPORTED_MODEL = 9;
     */
    EXPORTED_MODEL = 9,
}
// @generated message type with reflection information, may provide speed optimized methods
class LudwigDataset$Type extends MessageType<LudwigDataset> {
    constructor() {
        super("proto.artifacts.v1.LudwigDataset", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tasks", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            {
                no: 3,
                name: "data_modalities",
                kind: "scalar",
                repeat: 2 /*RepeatType.UNPACKED*/,
                T: 9 /*ScalarType.STRING*/,
            },
        ]);
    }
    create(value?: PartialMessage<LudwigDataset>): LudwigDataset {
        const message = { name: "", tasks: [], dataModalities: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined) reflectionMergePartial<LudwigDataset>(this, message, value);
        return message;
    }
    internalBinaryRead(
        reader: IBinaryReader,
        length: number,
        options: BinaryReadOptions,
        target?: LudwigDataset,
    ): LudwigDataset {
        let message = target ?? this.create(),
            end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* repeated string tasks */ 2:
                    message.tasks.push(reader.string());
                    break;
                case /* repeated string data_modalities */ 3:
                    message.dataModalities.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(
                            `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
                        );
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LudwigDataset, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "") writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* repeated string tasks = 2; */
        for (let i = 0; i < message.tasks.length; i++) writer.tag(2, WireType.LengthDelimited).string(message.tasks[i]);
        /* repeated string data_modalities = 3; */
        for (let i = 0; i < message.dataModalities.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.dataModalities[i]);
        let u = options.writeUnknownFields;
        if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.artifacts.v1.LudwigDataset
 */
export const LudwigDataset = new LudwigDataset$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AvailableDatasetsResponse$Type extends MessageType<AvailableDatasetsResponse> {
    constructor() {
        super("proto.artifacts.v1.AvailableDatasetsResponse", [
            { no: 1, name: "datasets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LudwigDataset },
        ]);
    }
    create(value?: PartialMessage<AvailableDatasetsResponse>): AvailableDatasetsResponse {
        const message = { datasets: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined) reflectionMergePartial<AvailableDatasetsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(
        reader: IBinaryReader,
        length: number,
        options: BinaryReadOptions,
        target?: AvailableDatasetsResponse,
    ): AvailableDatasetsResponse {
        let message = target ?? this.create(),
            end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated proto.artifacts.v1.LudwigDataset datasets */ 1:
                    message.datasets.push(LudwigDataset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(
                            `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
                        );
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(
        message: AvailableDatasetsResponse,
        writer: IBinaryWriter,
        options: BinaryWriteOptions,
    ): IBinaryWriter {
        /* repeated proto.artifacts.v1.LudwigDataset datasets = 1; */
        for (let i = 0; i < message.datasets.length; i++)
            LudwigDataset.internalBinaryWrite(
                message.datasets[i],
                writer.tag(1, WireType.LengthDelimited).fork(),
                options,
            ).join();
        let u = options.writeUnknownFields;
        if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.artifacts.v1.AvailableDatasetsResponse
 */
export const AvailableDatasetsResponse = new AvailableDatasetsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PresignedUrlForUploadRequest$Type extends MessageType<PresignedUrlForUploadRequest> {
    constructor() {
        super("proto.artifacts.v1.PresignedUrlForUploadRequest", [
            { no: 1, name: "mime_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "expiry_seconds", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            {
                no: 3,
                name: "artifact_type",
                kind: "enum",
                T: () => ["proto.artifacts.v1.ArtifactType", ArtifactType, "ARTIFACT_TYPE_"],
            },
            { no: 4, name: "object_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        ]);
    }
    create(value?: PartialMessage<PresignedUrlForUploadRequest>): PresignedUrlForUploadRequest {
        const message = { mimeType: "", expirySeconds: 0, artifactType: 0, objectName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined) reflectionMergePartial<PresignedUrlForUploadRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(
        reader: IBinaryReader,
        length: number,
        options: BinaryReadOptions,
        target?: PresignedUrlForUploadRequest,
    ): PresignedUrlForUploadRequest {
        let message = target ?? this.create(),
            end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string mime_type */ 1:
                    message.mimeType = reader.string();
                    break;
                case /* int32 expiry_seconds */ 2:
                    message.expirySeconds = reader.int32();
                    break;
                case /* proto.artifacts.v1.ArtifactType artifact_type */ 3:
                    message.artifactType = reader.int32();
                    break;
                case /* string object_name */ 4:
                    message.objectName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(
                            `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
                        );
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(
        message: PresignedUrlForUploadRequest,
        writer: IBinaryWriter,
        options: BinaryWriteOptions,
    ): IBinaryWriter {
        /* string mime_type = 1; */
        if (message.mimeType !== "") writer.tag(1, WireType.LengthDelimited).string(message.mimeType);
        /* int32 expiry_seconds = 2; */
        if (message.expirySeconds !== 0) writer.tag(2, WireType.Varint).int32(message.expirySeconds);
        /* proto.artifacts.v1.ArtifactType artifact_type = 3; */
        if (message.artifactType !== 0) writer.tag(3, WireType.Varint).int32(message.artifactType);
        /* string object_name = 4; */
        if (message.objectName !== "") writer.tag(4, WireType.LengthDelimited).string(message.objectName);
        let u = options.writeUnknownFields;
        if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.artifacts.v1.PresignedUrlForUploadRequest
 */
export const PresignedUrlForUploadRequest = new PresignedUrlForUploadRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PresignedUrlForUploadResponse$Type extends MessageType<PresignedUrlForUploadResponse> {
    constructor() {
        super("proto.artifacts.v1.PresignedUrlForUploadResponse", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "object_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            {
                no: 3,
                name: "required_headers",
                kind: "map",
                K: 9 /*ScalarType.STRING*/,
                V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            },
        ]);
    }
    create(value?: PartialMessage<PresignedUrlForUploadResponse>): PresignedUrlForUploadResponse {
        const message = { url: "", objectName: "", requiredHeaders: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined) reflectionMergePartial<PresignedUrlForUploadResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(
        reader: IBinaryReader,
        length: number,
        options: BinaryReadOptions,
        target?: PresignedUrlForUploadResponse,
    ): PresignedUrlForUploadResponse {
        let message = target ?? this.create(),
            end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                case /* string object_name */ 2:
                    message.objectName = reader.string();
                    break;
                case /* map<string, string> required_headers */ 3:
                    this.binaryReadMap3(message.requiredHeaders, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(
                            `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
                        );
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap3(
        map: PresignedUrlForUploadResponse["requiredHeaders"],
        reader: IBinaryReader,
        options: BinaryReadOptions,
    ): void {
        let len = reader.uint32(),
            end = reader.pos + len,
            key: keyof PresignedUrlForUploadResponse["requiredHeaders"] | undefined,
            val: PresignedUrlForUploadResponse["requiredHeaders"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default:
                    throw new globalThis.Error(
                        "unknown map entry field for field proto.artifacts.v1.PresignedUrlForUploadResponse.required_headers",
                    );
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(
        message: PresignedUrlForUploadResponse,
        writer: IBinaryWriter,
        options: BinaryWriteOptions,
    ): IBinaryWriter {
        /* string url = 1; */
        if (message.url !== "") writer.tag(1, WireType.LengthDelimited).string(message.url);
        /* string object_name = 2; */
        if (message.objectName !== "") writer.tag(2, WireType.LengthDelimited).string(message.objectName);
        /* map<string, string> required_headers = 3; */
        for (let k of Object.keys(message.requiredHeaders))
            writer
                .tag(3, WireType.LengthDelimited)
                .fork()
                .tag(1, WireType.LengthDelimited)
                .string(k)
                .tag(2, WireType.LengthDelimited)
                .string(message.requiredHeaders[k])
                .join();
        let u = options.writeUnknownFields;
        if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.artifacts.v1.PresignedUrlForUploadResponse
 */
export const PresignedUrlForUploadResponse = new PresignedUrlForUploadResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegisterUploadedFileAsDatasetRequest$Type extends MessageType<RegisterUploadedFileAsDatasetRequest> {
    constructor() {
        super("proto.artifacts.v1.RegisterUploadedFileAsDatasetRequest", [
            { no: 1, name: "dataset_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "object_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "file_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        ]);
    }
    create(value?: PartialMessage<RegisterUploadedFileAsDatasetRequest>): RegisterUploadedFileAsDatasetRequest {
        const message = { datasetName: "", objectName: "", fileName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined) reflectionMergePartial<RegisterUploadedFileAsDatasetRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(
        reader: IBinaryReader,
        length: number,
        options: BinaryReadOptions,
        target?: RegisterUploadedFileAsDatasetRequest,
    ): RegisterUploadedFileAsDatasetRequest {
        let message = target ?? this.create(),
            end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string dataset_name */ 1:
                    message.datasetName = reader.string();
                    break;
                case /* string object_name */ 2:
                    message.objectName = reader.string();
                    break;
                case /* string file_name */ 3:
                    message.fileName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(
                            `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
                        );
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(
        message: RegisterUploadedFileAsDatasetRequest,
        writer: IBinaryWriter,
        options: BinaryWriteOptions,
    ): IBinaryWriter {
        /* string dataset_name = 1; */
        if (message.datasetName !== "") writer.tag(1, WireType.LengthDelimited).string(message.datasetName);
        /* string object_name = 2; */
        if (message.objectName !== "") writer.tag(2, WireType.LengthDelimited).string(message.objectName);
        /* string file_name = 3; */
        if (message.fileName !== "") writer.tag(3, WireType.LengthDelimited).string(message.fileName);
        let u = options.writeUnknownFields;
        if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.artifacts.v1.RegisterUploadedFileAsDatasetRequest
 */
export const RegisterUploadedFileAsDatasetRequest = new RegisterUploadedFileAsDatasetRequest$Type();
