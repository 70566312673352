import { useMutation, useQueryClient } from "@tanstack/react-query";
import { capitalize } from "lodash";
import { useState } from "react";
import { useRecoilState } from "recoil";
import {
    Dropdown,
    Popup,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from "semantic-ui-react";
import { role, user } from "../../api_generated";
import { useAuth0TokenOptions } from "../../data";
import metrics from "../../metrics/metrics";
import { USERS_CONSTANT } from "../../query";
import { USER_STATE } from "../../state/global";
import { getErrorMessage } from "../../utils/errors";
import { grantRoleV2 } from "./data";
import { generateUserRoleDropdownOptions } from "./util";

type RoleSelectionDropdownProps = {
    user: user;
};

const RoleSelectionDropdown = ({ user }: RoleSelectionDropdownProps) => {
    // Local state:
    const [selectedRole, setSelectedRole] = useState(user.role.toLowerCase());
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Query state:
    const queryClient = useQueryClient();
    const {
        mutate: grantRole,
        isError: grantRoleIsErrored,
        reset: resetMutation,
        error: grantRoleError,
    } = useMutation({
        mutationFn: (selectedRole: role) => {
            const requestBody = {
                role: selectedRole,
            };
            return grantRoleV2(user.uuid, requestBody, auth0TokenOptions);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [USERS_CONSTANT] });
        },
    });

    return (
        <Popup
            disabled={!grantRoleIsErrored}
            trigger={
                <Dropdown
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    placeholder="Select Role"
                    fluid
                    search
                    selection
                    options={generateUserRoleDropdownOptions()}
                    value={selectedRole}
                    onChange={(_, { value: roleName }) => {
                        resetMutation();
                        grantRole(roleName as role);
                        setSelectedRole(roleName as role);
                    }}
                    error={grantRoleIsErrored}
                />
            }
            content={getErrorMessage(grantRoleError)}
        />
    );
};

type RoleSelectionCellProps = {
    user: user;
};

const RoleSelectionCell = ({ user }: RoleSelectionCellProps) => {
    // User state:
    const [userContext] = useRecoilState(USER_STATE);

    // TODO: Does it actually make sense to not be able to downgrade admins?
    // const userIsAdmin = user.role.toLowerCase() === role.ADMIN || user.role.toLocaleLowerCase() === role.SUPPORT;
    const isViewerAdmin = Boolean(userContext?.isSystemUser);
    const formattedRoleName = capitalize(user.role);

    // if (userIsAdmin) {
    //     return <>{formattedRoleName}</>;
    // }

    if (isViewerAdmin) {
        return <RoleSelectionDropdown user={user} />;
    }

    return (
        <Popup
            className={"transition-scale"}
            trigger={<span>{formattedRoleName}</span>}
            content={"Only admins can change user roles."}
        />
    );
};

type MembersTableV2Props = {
    users?: Array<user>;
};

const MembersTableV2 = ({ users }: MembersTableV2Props) => {
    // User state:
    const [userContext] = useRecoilState(USER_STATE);

    if (!Array.isArray(users)) {
        return null;
    }

    return (
        <Table>
            <TableHeader>
                <TableRow>
                    <TableHeaderCell>Name</TableHeaderCell>
                    <TableHeaderCell>Username</TableHeaderCell>
                    <TableHeaderCell>Email Address</TableHeaderCell>
                    <TableHeaderCell>Auth Method(s)</TableHeaderCell>
                    <TableHeaderCell>Role</TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBody>
                {users.map((user) => {
                    let name = user.name;
                    if (name === "") {
                        name = "--";
                    }
                    if (user.uuid === userContext?.uuid) {
                        name += " (You!)";
                    }

                    return (
                        <TableRow key={user.uuid}>
                            <TableCell>{name}</TableCell>
                            <TableCell>{user.username}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.authMethods.join(", ")}</TableCell>
                            <TableCell>
                                <RoleSelectionCell user={user} />
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default MembersTableV2;
