// @generated by protobuf-ts 2.9.1 with parameter long_type_number
// @generated from protobuf file "predibase_api/iterml/v1/iterml.proto" (package "proto.iterml.v1", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * A recommended experiment for a model version or model repo.
 *
 * @generated from protobuf message proto.iterml.v1.RecommendedExperiment
 */
export interface RecommendedExperiment {
    /**
     * The model version or model repo to which this recommendation applies.
     *
     * @generated from protobuf field: string base_model_uuid = 1;
     */
    baseModelUuid: string;
    /**
     * The name of the recommendation.
     *
     * @generated from protobuf field: string title = 2;
     */
    title: string;
    /**
     * The description of the recommendation.
     *
     * @generated from protobuf field: string description = 3;
     */
    description: string;
    /**
     * The description used for the recommended experiment.
     *
     * @generated from protobuf field: string model_version_description = 4;
     */
    modelVersionDescription: string;
    /**
     * The recommended experiments' config, as a flat json string.
     *
     * @generated from protobuf field: string new_config_json_string = 5;
     */
    newConfigJsonString: string;
    /**
     * A list of parameters and how they should be changed. Usually one parameter,
     * but may sometimes involve multiple parameters.
     *
     * @generated from protobuf field: repeated proto.iterml.v1.ParameterChange parameter_changes = 6;
     */
    parameterChanges: ParameterChange[];
}
/**
 * List of recommended experiments.
 *
 * @generated from protobuf message proto.iterml.v1.RecommendedExperimentList
 */
export interface RecommendedExperimentList {
    /**
     * @generated from protobuf field: repeated proto.iterml.v1.RecommendedExperiment recommended_experiments = 1;
     */
    recommendedExperiments: RecommendedExperiment[];
}
/**
 * @generated from protobuf message proto.iterml.v1.ParameterChange
 */
export interface ParameterChange {
    /**
     * The name of the parameter to change.
     *
     * @generated from protobuf field: string parameter_name = 1;
     */
    parameterName: string;
    /**
     * The display name of the parameter to change.
     *
     * @generated from protobuf field: string display_name = 2;
     */
    displayName: string;
    /**
     * The value of the parameter before the change.
     *
     * @generated from protobuf field: string original_value = 3;
     */
    originalValue: string;
    /**
     * The value to change the parameter to.
     *
     * @generated from protobuf field: string new_value = 4;
     */
    newValue: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class RecommendedExperiment$Type extends MessageType<RecommendedExperiment> {
    constructor() {
        super("proto.iterml.v1.RecommendedExperiment", [
            { no: 1, name: "base_model_uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "model_version_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "new_config_json_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            {
                no: 6,
                name: "parameter_changes",
                kind: "message",
                repeat: 1 /*RepeatType.PACKED*/,
                T: () => ParameterChange,
            },
        ]);
    }
    create(value?: PartialMessage<RecommendedExperiment>): RecommendedExperiment {
        const message = {
            baseModelUuid: "",
            title: "",
            description: "",
            modelVersionDescription: "",
            newConfigJsonString: "",
            parameterChanges: [],
        };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined) reflectionMergePartial<RecommendedExperiment>(this, message, value);
        return message;
    }
    internalBinaryRead(
        reader: IBinaryReader,
        length: number,
        options: BinaryReadOptions,
        target?: RecommendedExperiment,
    ): RecommendedExperiment {
        let message = target ?? this.create(),
            end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string base_model_uuid */ 1:
                    message.baseModelUuid = reader.string();
                    break;
                case /* string title */ 2:
                    message.title = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* string model_version_description */ 4:
                    message.modelVersionDescription = reader.string();
                    break;
                case /* string new_config_json_string */ 5:
                    message.newConfigJsonString = reader.string();
                    break;
                case /* repeated proto.iterml.v1.ParameterChange parameter_changes */ 6:
                    message.parameterChanges.push(ParameterChange.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(
                            `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
                        );
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(
        message: RecommendedExperiment,
        writer: IBinaryWriter,
        options: BinaryWriteOptions,
    ): IBinaryWriter {
        /* string base_model_uuid = 1; */
        if (message.baseModelUuid !== "") writer.tag(1, WireType.LengthDelimited).string(message.baseModelUuid);
        /* string title = 2; */
        if (message.title !== "") writer.tag(2, WireType.LengthDelimited).string(message.title);
        /* string description = 3; */
        if (message.description !== "") writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* string model_version_description = 4; */
        if (message.modelVersionDescription !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.modelVersionDescription);
        /* string new_config_json_string = 5; */
        if (message.newConfigJsonString !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.newConfigJsonString);
        /* repeated proto.iterml.v1.ParameterChange parameter_changes = 6; */
        for (let i = 0; i < message.parameterChanges.length; i++)
            ParameterChange.internalBinaryWrite(
                message.parameterChanges[i],
                writer.tag(6, WireType.LengthDelimited).fork(),
                options,
            ).join();
        let u = options.writeUnknownFields;
        if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.iterml.v1.RecommendedExperiment
 */
export const RecommendedExperiment = new RecommendedExperiment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecommendedExperimentList$Type extends MessageType<RecommendedExperimentList> {
    constructor() {
        super("proto.iterml.v1.RecommendedExperimentList", [
            {
                no: 1,
                name: "recommended_experiments",
                kind: "message",
                repeat: 1 /*RepeatType.PACKED*/,
                T: () => RecommendedExperiment,
            },
        ]);
    }
    create(value?: PartialMessage<RecommendedExperimentList>): RecommendedExperimentList {
        const message = { recommendedExperiments: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined) reflectionMergePartial<RecommendedExperimentList>(this, message, value);
        return message;
    }
    internalBinaryRead(
        reader: IBinaryReader,
        length: number,
        options: BinaryReadOptions,
        target?: RecommendedExperimentList,
    ): RecommendedExperimentList {
        let message = target ?? this.create(),
            end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated proto.iterml.v1.RecommendedExperiment recommended_experiments */ 1:
                    message.recommendedExperiments.push(
                        RecommendedExperiment.internalBinaryRead(reader, reader.uint32(), options),
                    );
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(
                            `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
                        );
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(
        message: RecommendedExperimentList,
        writer: IBinaryWriter,
        options: BinaryWriteOptions,
    ): IBinaryWriter {
        /* repeated proto.iterml.v1.RecommendedExperiment recommended_experiments = 1; */
        for (let i = 0; i < message.recommendedExperiments.length; i++)
            RecommendedExperiment.internalBinaryWrite(
                message.recommendedExperiments[i],
                writer.tag(1, WireType.LengthDelimited).fork(),
                options,
            ).join();
        let u = options.writeUnknownFields;
        if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.iterml.v1.RecommendedExperimentList
 */
export const RecommendedExperimentList = new RecommendedExperimentList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ParameterChange$Type extends MessageType<ParameterChange> {
    constructor() {
        super("proto.iterml.v1.ParameterChange", [
            { no: 1, name: "parameter_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "original_value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "new_value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        ]);
    }
    create(value?: PartialMessage<ParameterChange>): ParameterChange {
        const message = { parameterName: "", displayName: "", originalValue: "", newValue: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined) reflectionMergePartial<ParameterChange>(this, message, value);
        return message;
    }
    internalBinaryRead(
        reader: IBinaryReader,
        length: number,
        options: BinaryReadOptions,
        target?: ParameterChange,
    ): ParameterChange {
        let message = target ?? this.create(),
            end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string parameter_name */ 1:
                    message.parameterName = reader.string();
                    break;
                case /* string display_name */ 2:
                    message.displayName = reader.string();
                    break;
                case /* string original_value */ 3:
                    message.originalValue = reader.string();
                    break;
                case /* string new_value */ 4:
                    message.newValue = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(
                            `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
                        );
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ParameterChange, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string parameter_name = 1; */
        if (message.parameterName !== "") writer.tag(1, WireType.LengthDelimited).string(message.parameterName);
        /* string display_name = 2; */
        if (message.displayName !== "") writer.tag(2, WireType.LengthDelimited).string(message.displayName);
        /* string original_value = 3; */
        if (message.originalValue !== "") writer.tag(3, WireType.LengthDelimited).string(message.originalValue);
        /* string new_value = 4; */
        if (message.newValue !== "") writer.tag(4, WireType.LengthDelimited).string(message.newValue);
        let u = options.writeUnknownFields;
        if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.iterml.v1.ParameterChange
 */
export const ParameterChange = new ParameterChange$Type();
