import React from "react";
import { useRecoilState } from "recoil";
import { Grid, Icon, Message, Segment } from "semantic-ui-react";

import PaymentForm from "../settings/billing/PaymentForm";
import { SESSION_STATE } from "../state/global";
import { PREDIBASE_LIGHT_GRAY, SEMANTIC_BLUE, SEMANTIC_BLUE_ACTIVE } from "../utils/colors";

const premiumPoints = [
    <>
        Pay-as-you-go pricing billed on the 1st of each month.&nbsp;
        {
            // eslint-disable-next-line react/jsx-no-target-blank
            <a href="https://predibase.com/pricing" target="_blank" rel="noopener">
                See pricing
            </a>
        }
    </>,
    "Fine-tune LLMs up to 70 billion parameters, with access to A100s",
    "Serve unlimited adapters on a single GPU with LoRAX",
    "Deploy 1 private serverless deployment (up to 70B parameters), with autoscaling and scale to 0",
    "Experiment using our shared serverless endpoints for free (up to 1m tokens/day)",
    "Two concurrent training jobs",
    "Access to customer support via chat and email",
];

const enterprisePoints = [
    "Deploy to your virtual private cloud (VPC)",
    "Discounted compute pricing",
    "Reserved instances to guarantee scaling to meet your needs",
    "Additional hands-on support from our ML experts",
    "Additional concurrent jobs",
    "Uptime SLAs and privacy",
];

const UpgradeFreeTrialModalContents = (props: {
    submitting: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const { submitting, setOpen, setSubmitting } = props;
    const [session] = useRecoilState(SESSION_STATE);

    return (
        <Grid stackable columns={2}>
            <Grid.Row>
                <Grid.Column>
                    <h2 style={{ fontSize: "1.28571rem", marginBottom: "1.71rem" }}>Developer Tier</h2>
                    <Message
                        color="blue"
                        content="Add your credit card to upgrade to our Developer Tier today!"
                        style={{ marginBottom: "1.86rem", color: SEMANTIC_BLUE_ACTIVE }}
                    />
                    <ul
                        style={{
                            textAlign: "left",
                            listStyle: "none",
                            padding: 0,
                            margin: 0,
                            marginBottom: "1.71rem",
                        }}
                    >
                        {premiumPoints.map((point, idx) => (
                            // TODO: Temporary hack. Do not use array indices as element keys:
                            <li key={`point_${idx}`} style={{ marginBottom: "1.14rem", display: "flex" }}>
                                <Icon name={"check circle outline"} color="blue" style={{ marginRight: "0.86rem" }} />
                                {point}
                            </li>
                        ))}
                    </ul>
                    <Segment style={{ background: PREDIBASE_LIGHT_GRAY, padding: "1.71rem", marginBottom: "1.71rem" }}>
                        <h2 style={{ fontSize: "1rem", marginBottom: "1.14rem", fontWeight: 700 }}>
                            We also offer an Enterprise Tier:
                        </h2>
                        <ul style={{ textAlign: "left" }}>
                            {enterprisePoints.map((point) => (
                                <li key={point}>{point}</li>
                            ))}
                        </ul>
                    </Segment>
                    <p>
                        Contact <a href="mailto:support@predibase.com">support@predibase.com</a> for any questions or if
                        you would like to discuss the Enterprise Tier.
                    </p>
                </Grid.Column>
                <Grid.Column>
                    <div
                        style={{
                            padding: `${24 / 14}rem`,
                            border: "1px solid #DEDEDF",
                            borderTop: `9px solid ${SEMANTIC_BLUE}`,
                            borderRadius: "4px",
                        }}
                    >
                        <PaymentForm
                            billingDetails={{
                                name: `${session?.identity?.traits?.name?.first} ${session?.identity?.traits?.name?.last}`,
                            }}
                            submitting={submitting}
                            setSubmitting={setSubmitting}
                            setOpen={setOpen}
                        />
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default UpgradeFreeTrialModalContents;
